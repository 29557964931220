import React from "react";

export default function OfferTape() {
 return (
  <div className="bg-themecolor fixed top-0 w-full z-10  text-[14px]">
   <div className="container py-2 flex items-center justify-center gap-2">
    <h1 className="text-white">KOSOVA KUALIFIKOHET NE BOTEROR!</h1>
    <h1 className="text-[#DAA53D] cursor-pointer">SHIKO LOJEN TANI!</h1>
   </div>
  </div>
 );
}
