import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import OfferTape from "../components/Offers/OfferTape";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import { FaUserCircle } from "react-icons/fa";

export default function Navbar() {
 const location = useLocation();
 const currentPage = location.pathname;

 const [isScrolled, setIsScrolled] = useState(false);
 const scrollThreshold = 100;

 const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
 const [mobileMenu, setMobileMenu] = useState(false);

 const isEventDetailPage = /\/events\/\d+/.test(currentPage);
 const isLoginPage = currentPage === "/login";
 const isRegisterPage = currentPage === "/register";
 const isApplyPage = currentPage === "/apply";

 const applyThemeColor = isScrolled || isEventDetailPage || isLoginPage || isRegisterPage || isApplyPage;

 useEffect(() => {
  const handleScroll = () => {
   setIsScrolled(window.scrollY > scrollThreshold);
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
   window.removeEventListener("scroll", handleScroll);
  };
 }, [scrollThreshold]);

 const links = [
  { name: "Home", path: "/" },
  { name: "Events", path: "/events" },
  { name: "About us", path: "/about-us" },
  { name: "Contact us", path: "/contact-us" },
 ];

 return (
  <>
   {!isMobile ? (
    <>
     <OfferTape />
     <div
      className={`fixed top-9 w-full z-10 px-12 transition-all duration-300 ${
       applyThemeColor ? "bg-themecolor/80 backdrop-blur-md" : "bg-transparent"
      }`}
     >
      <div className="container mx-auto">
       <div className="flex justify-between items-center py-2 min-h-[65px]">
        <Link to="/">
         <img src="/assets/images/fesklogo.svg" alt="logo" className="w-[140px] h-[40px]" />
        </Link>
        <ul className="flex gap-6">
         {links.map(({ name, path }) => (
          <li key={path} className="relative group cursor-pointer text-[18px]">
           <Link to={path}>
            <span className={currentPage === path ? "text-white" : "text-gray-400"}>{name}</span>
           </Link>
           <div
            className={`absolute left-0 w-full h-[2px] bg-gray-300 transition-all duration-300 transform scale-x-0 group-hover:scale-x-100 ${
             currentPage === path ? "scale-x-100" : ""
            }`}
           />
          </li>
         ))}
        </ul>
        <div className="flex items-center gap-4 text-[15px]">
         <Link to="/login">
          <span className={isLoginPage ? "text-black" : "text-gray-400"}>Login</span>
         </Link>
         <Link to="/register">
          <button className="bg-themecolor text-white py-1 px-2 rounded">Get Started</button>
         </Link>
        </div>
       </div>
      </div>
     </div>
    </>
   ) : (
    <>
     {" "}
     {isMobile && (
      <>
       <div className="bg-themecolor  top-0 left-0 right-0  flex justify-between p-8 z-[100]">
        <div className="flex justify-center items-center gap-20">
         <button onClick={() => setMobileMenu(true)}>
          <img src="/assets/images/menuLogo.svg" alt="logo" />
         </button>
         <img src="/assets/images/fesklogo.svg" alt="logo" />
         <Link to="/login">
          <FaUserCircle className="w-[25px] h-[25px]" color="white" />
         </Link>
        </div>
       </div>
       <MobileMenu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      </>
     )}
    </>
   )}
  </>
 );
}
