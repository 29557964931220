import { useEffect } from "react";
import "./App.css";
import AppRoutes from "./routes";
import Navbar from "../src/layouts/Navbar";
import Footer from "./layouts/Footer";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
 const location = useLocation();

 useEffect(() => {
  window.scrollTo(0, 0);
 }, [location]);

 useEffect(() => {
  AOS.init();
 }, []);

 return (
  <>
   <Navbar />
   <AppRoutes />
   <Footer />
  </>
 );
}

export default App;
