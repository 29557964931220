import React from "react";
import background from "../../mediaAssets/eventImage.svg";
import NewsCardHome from "../../components/Cards/NewsCardHome";

export default function Events() {
 return (
  <div>
   {" "}
   <div
    className="relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center"
    style={{ backgroundImage: `url(${background})`, height: "500px" }}
   >
    <div
     className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
     style={{ backgroundColor: "rgba(0.4, 0, 0, 0.6)" }}
    >
     <div className="flex flex-col h-full items-center justify-center">
      <h1 className="bg-themecolor p-2 text-white rounded-lg font-bold w-[150px] text-[20px]" data-aos="zoom-in-down">
       FESK Events
      </h1>
      <div className="text-white">
       <h2 className="text-[40px] font-semibold py-4" data-aos="zoom-out-up">
        Evente te ardhshme
       </h2>
      </div>
     </div>
    </div>
   </div>
   <div className="container !mt-[-80px]">
    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 " data-aos="zoom-in-down">
     <NewsCardHome />
     <NewsCardHome />
     <NewsCardHome />
     <NewsCardHome />
     <NewsCardHome />
     <NewsCardHome />
     <NewsCardHome />
     <NewsCardHome />
    </div>
   </div>
  </div>
 );
}
