import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Buttons/Button";

export default function Login() {
 return (
  <div className="container pt-[100px]">
   <div className="md:mx-[200px] flex md:flex-row flex-col justify-center gap-12 py-12">
    <div className="md:mr-[100px] md:w-1/2">
     <h1 className="text-[40px] font-semibold">Sign in</h1>
     <p className="py-4 text-[20px]">Please login to continue to your account</p>
     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="name">Email</label>
      <input type="text" id="name" name="name" className="border rounded-lg p-3 outline-none" placeholder="write your email" required />
     </div>
     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="name">Password</label>
      <input type="password" id="name" name="name" className="border rounded-lg p-3 outline-none" placeholder="write your password" required />
     </div>
     <p className="text-center">
      Need an account?{" "}
      <Link to="/register" className="text-themecolor underline">
       Create one
      </Link>
     </p>
     <div className="w-full py-4">
      <Button type="ddd" title="Kyçu me Google" classes="!w-full border rounded-[8px] my-4" logo="/assets/images/googleLogin.svg" />
      <Button type="ddd" title="Kyçu me Facebook" classes="!w-full border rounded-[8px] " logo="/assets/images/facebookLogin.svg" />
     </div>
    </div>
    <Link className="md:w-1/2" to="/apply">
     <img src="/assets/images/loginImageFesk.svg" alt="loginImageFesk" className="cursor-pointer" />
    </Link>
   </div>
  </div>
 );
}
