import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Buttons/Button";

export default function Signup() {
 return (
  <div className="container pt-[100px]">
   <div className="md:mx-[200px] flex md:flex-row flex-col justify-center gap-12 py-12">
    <div className="md:mr-[100px] md:w-1/2">
     <h1 className="text-[40px] font-semibold">Sign up</h1>
     <p className="py-4 text-[20px]">Please fill in the details to create your account</p>

     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="name">Name</label>
      <input type="text" id="name" name="name" className="border rounded-lg p-3 outline-none" placeholder="Enter your name" required />
     </div>

     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="dob">Date of Birth</label>
      <input type="date" id="dob" name="dob" className="border rounded-lg p-3 outline-none" required />
     </div>

     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="password">Password</label>
      <input
       type="password"
       id="password"
       name="password"
       className="border rounded-lg p-3 outline-none"
       placeholder="Enter your password"
       required
      />
     </div>

     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="confirmPassword">Confirm Password</label>
      <input
       type="password"
       id="confirmPassword"
       name="confirmPassword"
       className="border rounded-lg p-3 outline-none"
       placeholder="Confirm your password"
       required
      />
     </div>

     <p className="text-center">
      Already have an account?{" "}
      <Link to="/login" className="text-themecolor underline">
       Sign in
      </Link>
     </p>

     <div className="w-full py-4">
      <Button type="ddd" title="Sign up with Google" classes="!w-full border rounded-[8px] my-4" logo="/assets/images/googleLogin.svg" />
      <Button type="ddd" title="Sign up with Facebook" classes="!w-full border rounded-[8px]" logo="/assets/images/facebookLogin.svg" />
     </div>
    </div>

    <Link className="md:w-1/2" to="/apply">
     <img src="/assets/images/loginImageFesk.svg" alt="loginImageFesk" className="cursor-pointer" />
    </Link>
   </div>
  </div>
 );
}
