import React from "react";
import background from "../mediaAssets/ourStory.svg";
import backgroundSecond from "../mediaAssets/aboutus4.svg";

export default function About() {
 return (
  <div>
   <div
    className="relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center"
    style={{ backgroundImage: `url(${background})`, height: "500px" }}
   >
    <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
     <div className="flex flex-col h-full items-center justify-center">
      <img alt="logo" src="/assets/images/fesklogo.svg" className="w-[300px] my-4" data-aos="zoom-out-up" />
      <div className="text-white" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
       <h2 className="text-[40px] font-semibold">Our Story</h2>
       <h4 className="mb-6 text-[24px] font-light">Mundësia juaj për të shndërruar pasionin për lojërat elektronike në sukses.</h4>
      </div>
     </div>
    </div>
   </div>
   <div className="container md:py-24 py-12">
    <div className="flex md:flex-row  justify-between md:mx-24 md:gap-20 gap-10">
     <div className="flex flex-col">
      <img alt="about" src="/assets/images/aboutus/aboutus1.svg" />
      <h1 className="md:text-[40px] text-[20px] md:py-10 py-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
       We are here to help the customers to get their success.
      </h1>
      <p className="md:py-4 py-2 md:text-[20px] text-[10px]">
       We share common trends and strategies for improving your rental income and making sure you stay in high demand of service.
      </p>
      <p className="py-4 md:text-[20px] text-[10px]">With lots of unique blocks you can easily build a page without lorem ipsum any other page. </p>
     </div>
     <div className="flex flex-col md:gap-6 gap-2">
      <h1 className="md:text-[40px] text-[20px]">Play with very talented young boys from Kosova</h1>
      <p className="py-4 md:text-[20px] text-[10px]">Bashkohuni me ne per te shendrruar pasionin tuaj per lojrat elektronike ne sukses!</p>
      <img alt="about" src="/assets/images/aboutus/aboutus2.svg" />
      <img alt="about" src="/assets/images/aboutus/aboutus3.svg" className="pt-4" />
     </div>
    </div>
   </div>
   <div>
    <div className="container text-center py-12" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
     <h1 className="text-[40px] font-semibold mb-4">WHO IS FEDERATA ESPORTS KOSOVA</h1>
     <p className="text-[18px]">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
      but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
      sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
      Ipsum.
     </p>
    </div>
    <div
     className="relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center"
     style={{ backgroundImage: `url(${backgroundSecond})`, height: "500px" }}
    >
     <div
      className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
      style={{ backgroundColor: "rgba(0, 0, 0.2, 0.2)" }}
     >
      <div className="flex flex-col h-full items-center ">
       <img alt="logo" src="/assets/images/fesklogo.svg" className="w-[300px] mt-12 " />
      </div>
     </div>
    </div>
   </div>
   <div className="container  py-12" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div className="text-center">
     <h1 className="text-[40px] font-semibold mb-4">FESK Members</h1>
     <p className="text-[18px]">
      We share common trends and strategies for improving your rental income and making sure you stay in high demand of service.We share common trends
      and strategies for improving your rental income and making sure you stay in high demand of service.
     </p>
    </div>
    <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4 py-12">
     <div className="border shadow-xl rounded-lg cursor-pointer group">
      <img
       alt="members"
       src="/assets/images/members/member.svg"
       className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
      />
      <div className="p-2 flex flex-col gap-2">
       <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
       <h2 className="font-semibold text-[14px]">Software Engineer</h2>
       <p>There are many variations of passages of Lorem Ipsum available</p>
      </div>
     </div>
     <div className="border shadow-xl rounded-lg cursor-pointer group">
      <img
       alt="members"
       src="/assets/images/members/member.svg"
       className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
      />
      <div className="p-2 flex flex-col gap-2">
       <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
       <h2 className="font-semibold text-[14px]">Software Engineer</h2>
       <p>There are many variations of passages of Lorem Ipsum available</p>
      </div>
     </div>
     <div className="border shadow-xl rounded-lg cursor-pointer group">
      <img
       alt="members"
       src="/assets/images/members/member.svg"
       className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
      />
      <div className="p-2 flex flex-col gap-2">
       <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
       <h2 className="font-semibold text-[14px]">Software Engineer</h2>
       <p>There are many variations of passages of Lorem Ipsum available</p>
      </div>
     </div>
     <div className="border shadow-xl rounded-lg cursor-pointer group">
      <img
       alt="members"
       src="/assets/images/members/member.svg"
       className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
      />
      <div className="p-2 flex flex-col gap-2">
       <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
       <h2 className="font-semibold text-[14px]">Software Engineer</h2>
       <p>There are many variations of passages of Lorem Ipsum available</p>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
}
