import React from "react";

export default function ClubsCardHome() {
 return (
  <div className="flex justify-between m-4 ">
   <div className="">
    <div>
     <img src="/assets/images/clubs/naviClub.svg" alt="naviClub" />
     <p className="text-center font-bold">NAVI</p>
    </div>
    <div>
     <img src="/assets/images/clubs/naviClub.svg" alt="naviClub" />
     <p className="text-center font-bold">NAVI</p>
    </div>
   </div>
   <div className="mt-16">
    <div>
     <img src="/assets/images/clubs/naviClub.svg" alt="naviClub" />
     <p className="text-center font-bold">NAVI</p>
    </div>
    <div>
     <img src="/assets/images/clubs/naviClub.svg" alt="naviClub" />
     <p className="text-center font-bold">NAVI</p>
    </div>
   </div>
  </div>
 );
}
