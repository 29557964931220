import React from "react";

export default function AboutUsHome() {
 return (
  <div className="my-24 md:text-left text-center">
   <h1 className="text-[40px] font-bold" data-aos="fade-right">
    Rreth Nesh
   </h1>
   <div className="md:mx-24 mx-6 flex md:flex-row flex-col justify-between md:gap-16 gap-4">
    <img src="/assets/images/aboutusImage1.svg" alt="aboutusImage1" data-aos="fade-up" />
    <div>
     <div className="md:w-[80%] w-full">
      <h1 className="text-[40px] " data-aos="fade-left">
       Play with very talented young boys from Kosovo
      </h1>
      <p className="text-[22px] mt-4 leading-10	">Bashkohuni me ne per te shendrruar pasionin tuaj per lojrat elektronike ne sukses! </p>
     </div>
     <img src="/assets/images/aboutusImage2.svg" alt="aboutusImage1" data-aos="fade-down" />
    </div>
   </div>
  </div>
 );
}
