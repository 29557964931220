import React from "react";

export default function EventBlogById() {
 return (
  <div className="container px-4 pt-32 mx-auto">
   <div className="flex flex-col items-center gap-4 max-w-4xl mx-auto">
    <p className="bg-[#FFF1C5] p-2 rounded-full text-sm md:text-base">ESPORTS</p>
    <h1 className="text-2xl md:text-4xl text-center font-bold">KOSOVA KUALIFIKOHET NE BOTEROR!</h1>
    <p className="text-[#B3B3B3] text-center text-sm md:text-base">FESK - 03.06.2024 </p>
    <img src={"/assets/images/imageEvent.svg"} alt="main" className="w-full h-[200px] md:h-[300px] object-cover" />
    <div className="bg-[#D9D9D9] my-4 w-full h-px"></div>
    <p className="my-4">
     Kombëtarja e Kosovës në lojërat elektronike e ka siguruar kualifikimin në Kampionatin Botëror në Riad, Arabi Saudite. Kosova mposhti Letoninë në
     lojën e famshme “Counter Strike 2” për ta siguruar për herë të dytë paraqitjen në Botëror. Kosova e fitoi bindshëm 13:5 mapin e parë ndaj
     letonezëve, kurse mapi dytë ishte dramatik. Kosova ishte në avantazh por humbi pas vazhdimeve, 16:14. Mapi tretë vendosi fituesin. Dhe Kosova
     ishte ajo që shënoi fitore për të siguruar pjesëmarrjen në Botëror. Në mapin e tretë Kosova fitoi 13:7. Botërori këtë vit mbahet në Riad të
     Arabisë Saudite, ndërsa meritat më të mëdha pë ketë sukses të jashtëzakonshëm e kanë lojtarët: Arbnor “Dementor” Pacolli, Bledar “BledarD” Dula,
     Ensar “Caleyy” Ersuzi, Butrint “HYPERI1” Gashi dhe Arlind “debO” Sadiku. I falenderojmë të gjithë ata që na përkrahin dhe sponsorizojnë si:
     Ministria e Kulturës, Rinisë dhe Sportit, Drejtoria për Sport - Komuna e Prishtinës, EXFIS, telkos, Korporata Rugove dhe XPortalEsports. Urime
     dhe suksese në Riad! Me respekt, Federata Esports Kosova
    </p>
   </div>
  </div>
 );
}
