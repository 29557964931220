import React from "react";

export default function TeamPage() {
 return (
  <div className="container pt-[150px]">
   <div className="text-center">
    <h1 className="font-semibold text-[40px]">Kosova Team</h1>
    <p className="text-[16px] pb-12">
     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
    </p>
   </div>
   <div className="flex items-center justify-center">
    <div className="border shadow-xl rounded-lg cursor-pointer group">
     <img
      alt="members"
      src="/assets/images/members/member.svg"
      className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
     />
     <div className="p-2 flex flex-col gap-2">
      <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
      <h2 className="font-semibold text-[14px]">Software Engineer</h2>
      <p>There are many variations of passages of Lorem Ipsum available</p>
     </div>
    </div>
   </div>
   <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4 py-12">
    <div className="border shadow-xl rounded-lg cursor-pointer group">
     <img
      alt="members"
      src="/assets/images/members/member.svg"
      className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
     />
     <div className="p-2 flex flex-col gap-2">
      <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
      <h2 className="font-semibold text-[14px]">Software Engineer</h2>
      <p>There are many variations of passages of Lorem Ipsum available</p>
     </div>
    </div>
    <div className="border shadow-xl rounded-lg cursor-pointer group">
     <img
      alt="members"
      src="/assets/images/members/member.svg"
      className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
     />
     <div className="p-2 flex flex-col gap-2">
      <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
      <h2 className="font-semibold text-[14px]">Software Engineer</h2>
      <p>There are many variations of passages of Lorem Ipsum available</p>
     </div>
    </div>
    <div className="border shadow-xl rounded-lg cursor-pointer group">
     <img
      alt="members"
      src="/assets/images/members/member.svg"
      className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
     />
     <div className="p-2 flex flex-col gap-2">
      <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
      <h2 className="font-semibold text-[14px]">Software Engineer</h2>
      <p>There are many variations of passages of Lorem Ipsum available</p>
     </div>
    </div>
    <div className="border shadow-xl rounded-lg cursor-pointer group">
     <img
      alt="members"
      src="/assets/images/members/member.svg"
      className="w-full opacity-80 group-hover:opacity-100 transition-opacity rounded-lg "
     />
     <div className="p-2 flex flex-col gap-2">
      <h1 className="font-bold text-[18px]">Hordyn Spetimus</h1>
      <h2 className="font-semibold text-[14px]">Software Engineer</h2>
      <p>There are many variations of passages of Lorem Ipsum available</p>
     </div>
    </div>
   </div>
  </div>
 );
}
