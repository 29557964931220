import React from "react";
import { Link } from "react-router-dom";

export default function NewsCardHome() {
 return (
  <Link to="/events/2">
   <div className="shadow-4xl bg-[#FFFFFF] my-4 md:mr-6  rounded-lg cursor-pointer group transform hover:scale-[0.98] transition-transform duration-300 ">
    <div className="relative">
     <img alt="news" src="/assets/images/news/Bitmap.svg" className="rounded-lg w-full" />
     <span className="bg-themecolor text-white text-[12px] absolute top-2 left-2 p-2 rounded-lg">Dota 2</span>
     <span className="bg-themecolor text-white text-[12px] absolute bottom-[-15px] left-2 p-2 rounded-lg">03.06.2024</span>
    </div>
    <div className="h-[240px] pt-8 w-full">
     <h1 className="font-bold text-[22px]">Matches You Shouldn't Miss - Week 2 of the Esports World Cup</h1>
     <p className="my-2">After a phenomenal first week of the Esports World Cup, week 2 will bring more action for Dota 2, ML:BB, and Free Fire!</p>
     <button className="mb-2 border-2 border-themecolor rounded-lg bg-themecolor/20 w-[140px] p-1 relative  overflow-hidden">
      <div className="group-hover:p-3 transition-all duration-300">
       <span className="truncate absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        Read more
       </span>
      </div>
     </button>
    </div>
   </div>
  </Link>
 );
}
