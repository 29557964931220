import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../../components/ui/Buttons/Button";
import EventCard from "../../components/Cards/EventCard";

export default function EventsHome() {
 const slider = useRef(null);

 const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
   {
    breakpoint: 1280,
    settings: {
     slidesToShow: 3,
    },
   },
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 3,
    },
   },
   {
    breakpoint: 600,
    settings: {
     slidesToShow: 1,
    },
   },
  ],
 };

 return (
  <>
   <div className=" my-12 w-full ">
    <h1 className="text-[40px] font-bold">Evente</h1>
   </div>
   <div className="relative " data-aos="fade-right">
    <Slider ref={slider} {...settings}>
     <EventCard />
     <EventCard />
     <EventCard />
     <EventCard />
     <EventCard />
    </Slider>
    <div className="">
     <Button
      classes="bg-themecolor w-[39px] h-[39px] rounded-xl absolute top-2 left-2"
      title={
       <svg xmlns="http://www.w3.org/2000/svg" width="5.766" height="11.255" viewBox="0 0 5.766 11.255">
        <path
         id="fi-rr-angle-small-left"
         d="M10.151,11.2a.8.8,0,0,1,0-1.142l3.69-3.682a.8.8,0,1,0-1.133-1.142l-3.69,3.69a2.412,2.412,0,0,0,0,3.409l3.69,3.69a.8.8,0,1,0,1.133-1.142Z"
         transform="translate(-8.313 -4.999)"
         fill="#FFF"
        />
       </svg>
      }
      onClick={() => slider.current?.slickPrev()}
     />
     <Button
      classes="bg-themecolor w-[39px] h-[39px] rounded-xl absolute top-2 md:right-6 right-4"
      title={
       <svg xmlns="http://www.w3.org/2000/svg" width="5.766" height="11.256" viewBox="0 0 5.766 11.256">
        <path
         id="fi-rr-angle-small-right"
         d="M14.165,8.923l-3.69-3.69A.8.8,0,1,0,9.342,6.375l3.7,3.682a.8.8,0,0,1,0,1.142l-3.7,3.682a.8.8,0,0,0,1.133,1.142l3.69-3.69A2.412,2.412,0,0,0,14.165,8.923Z"
         transform="translate(-9.104 -4.999)"
         fill="#FFF"
        />
       </svg>
      }
      onClick={() => slider.current?.slickNext()}
     />
    </div>
   </div>
  </>
 );
}
