import React from "react";
import background from "../../mediaAssets/backgroundfesk.svg";
import { Link } from "react-router-dom";

export default function Header() {
 return (
  <div
   className="relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center"
   style={{ backgroundImage: `url(${background})`, height: "600px" }}
  >
   <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
    <div className="flex h-full items-center justify-center">
     <div className="text-white">
      <h2 className="text-[40px] md:text-[60px] font-semibold" data-aos="fade-down">
       FEDERATA ESPORTS KOSOVA
      </h2>
      <h4 className="my-6 md:my-6 text-[20px] md:text-[30px] font-light" data-aos="fade-up">
       Mundësia juaj për të shndërruar pasionin për lojërat elektronike në sukses.
      </h4>
      <Link to="/apply">
       <button
        type="button"
        className="rounded border-2 border-themecolor px-7 pb-[8px] pt-[10px] text-sm font-medium uppercase leading-normal text-neutral-50 hover:text-themecolor transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10
       backdrop-blur-sm bg-themecolor/30 "
        data-twe-ripple-init
        data-twe-ripple-color="light"
        data-aos="fade-up"
       >
        Apliko ne ekip
       </button>
      </Link>
     </div>
    </div>
   </div>
  </div>
 );
}
