import React from "react";
import backgroundSecond from "../mediaAssets/aboutus4.svg";
import Button from "../components/ui/Buttons/Button";

export default function Contact() {
 return (
  <div className="">
   <div
    className="relative overflow-hidden bg-cover bg-no-repeat p-12 text-center"
    style={{ backgroundImage: `url(${backgroundSecond})`, height: "350px" }}
   >
    <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
     <div className="flex flex-col h-full items-center justify-center">
      <div className="text-white mt-20">
       <h2 className="text-[40px] font-semibold">Contact us</h2>
       <h4 className="mb-6 text-[24px] font-light">Mundësia juaj për të shndërruar pasionin për lojërat elektronike në sukses.</h4>
      </div>
     </div>
    </div>
   </div>
   <div className="container ">
    <div className="flex md:flex-row flex-col justify-center md:mx-0 mx-auto md:gap-44 gap-12  my-12 ">
     <div className="flex items-start gap-4">
      <img alt="phone" src="/assets/images/contact/phone.svg" className="mt-1" />
      <div className="flex flex-col gap-2">
       <p className="text-[20px] font-semibold">Call us</p>
       <div>
        <p>
         <a href="tel:+38344123123">+383 44 123 123</a>
        </p>
        <p>
         <a href="tel:+38344123123">+383 44 123 123</a>
        </p>
       </div>
      </div>
     </div>
     <div className="flex  items-start gap-4">
      <img alt="email" src="/assets/images/contact/email.svg" className="mt-1" />
      <div className="flex flex-col gap-2">
       <p className="text-[20px] font-semibold">Email us</p>
       <div>
        <p>
         <a href="mailto:support@fesk.gg">support@fesk.gg</a>
        </p>
        <p>
         <a href="mailto:info@fesk.gg">info@fesk.gg</a>
        </p>
       </div>
      </div>
     </div>
     <div className="flex items-start gap-4">
      <img alt="location" src="/assets/images/contact/pin.svg" className="mt-1" />
      <div className="flex flex-col gap-2">
       <p className="text-[20px] font-semibold">Visit us</p>
       <div>
        <p>
         <a
          href="https://www.google.com/maps/search/?api=1&query=Rr.+Eqrem+%C3%87abej+31,+Prishtin%C3%AB,+10000+Republika+e+Kosov%C3%ABs"
          target="_blank"
          rel="noopener noreferrer"
         >
          Rr. Eqrem Çabej 31, Prishtinë,
         </a>
        </p>
        <p>10000 Republika e Kosovës</p>
       </div>
      </div>
     </div>
    </div>
    <form className="form border rounded p-4 md:m-24">
     <div className="grid grid-cols-2 gap-4">
      <div className="flex flex-col gap-2">
       <label htmlFor="name">First & Last Name</label>
       <input type="text" id="name" name="name" className="border rounded p-2 outline-none" placeholder="i.e. John Doe" required />
      </div>
      <div className="flex flex-col gap-2">
       <label htmlFor="email">Email</label>
       <input type="email" id="email" name="email" className="border rounded p-2 outline-none" placeholder="i.e. john@mail.com" required />
      </div>
      <div className="flex flex-col gap-2">
       <label htmlFor="phone">Phone number</label>
       <input type="tel" id="phone" name="phone" className="border rounded p-2 outline-none" placeholder="i.e. 044 208 389" required />
      </div>
      <div className="flex flex-col gap-2">
       <label htmlFor="subject">Subject</label>
       <input type="text" id="subject" name="subject" className="border rounded p-2 outline-none" placeholder="i.e. I need help" required />
      </div>
     </div>
     <div className="flex flex-col gap-2 my-4">
      <label htmlFor="message">Message</label>
      <textarea id="message" name="message" className="border rounded p-2 outline-none min-h-[200px]" placeholder="Type your message" required />
     </div>
     <Button title="Send" classes="bg-themecolor text-white" />
    </form>
   </div>
  </div>
 );
}
