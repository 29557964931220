import React, { useState } from "react";
import { Link } from "react-router-dom";

const Apply = () => {
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [selectedTeam, setSelectedTeam] = useState(null);

 const teams = [
  {
   team: "Kosova",
   players: ["/assets/images/players.svg"],
   coach: "/assets/images/coach.svg",
   game: "CS2",
  },
  {
   team: "Kosova 2",
   players: ["/assets/images/players.svg"],
   coach: "/assets/images/coach.svg",
   game: "DOTA",
  },
 ];

 const openModal = (team) => {
  setSelectedTeam(team);
  setIsModalOpen(true);
 };

 const closeModal = () => {
  setIsModalOpen(false);
 };

 return (
  <div className="container mx-auto my-8 px-4">
   <div className="pt-[150px] text-center">
    <h1 className="font-semibold text-[40px]">Teams</h1>
    <p className="pt-4 mb-12 text-[20px]">Bashkohuni me ne per te shendrruar pasionin tuaj per lojrat elektronike ne sukses!</p>
   </div>
   <div className="overflow-x-auto">
    <table className="w-full text-left border-collapse mb-12">
     <thead>
      <tr>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Team</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Players</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Coach</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Game</th>
       <th className="py-4 px-6 bg-gray-100 font-semibold text-sm">Action</th>
      </tr>
     </thead>
     <tbody>
      {teams.map((team, index) => (
       <tr key={index} className="border-t">
        <Link to={`/team/1`} className="text-themecolor">
         <td className="py-4 px-6">{team.team}</td>
        </Link>
        <td className="py-4 px-6">
         <div className="flex">
          {team.players.map((player, idx) => (
           <img key={idx} src={player} alt="Player" className="rounded-full border-2 border-white" />
          ))}
         </div>
        </td>
        <td className="py-4 px-6">
         <img src={team.coach} alt="Coach" className="rounded-full border-2 border-white" />
        </td>
        <td className="py-4 px-6">{team.game}</td>
        <td className="py-4 px-6">
         <button className="bg-blue-600 text-white py-2 px-4 rounded" onClick={() => openModal(team)}>
          Apply
         </button>
        </td>
       </tr>
      ))}
     </tbody>
    </table>
   </div>

   {isModalOpen && selectedTeam && (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
     <div className="bg-white p-8 rounded-lg">
      <h2 className="text-xl mb-4">Apply to {selectedTeam.team}</h2>
      <form>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">First Name</label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" placeholder="Enter your first name" />
       </div>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" placeholder="Enter your last name" />
       </div>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="email" placeholder="Enter your email" />
       </div>
       <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Why do you want to apply to this team?</label>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" placeholder="Write your message" />
       </div>
       <div className="flex justify-end">
        <button type="button" className="bg-gray-500 text-white py-2 px-4 rounded mr-2" onClick={closeModal}>
         Cancel
        </button>
        <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded">
         Submit
        </button>
       </div>
      </form>
     </div>
    </div>
   )}
  </div>
 );
};

export default Apply;
