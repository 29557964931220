import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Events from "../pages/EventsPages/Events";
import EventBlogById from "../pages/EventsPages/EventBlogById";
import Login from "../pages/AuthPages/Login";
import Signup from "../pages/AuthPages/Signup";
import Apply from "../pages/Apply";
import TeamPage from "../pages/TeamPage";

const AppRoutes = () => {
 return (
  <Routes>
   <Route path="/" element={<HomePage />}></Route>
   <Route path="/about-us" element={<About />}></Route>
   <Route path="/contact-us" element={<Contact />}></Route>
   <Route path="/events" element={<Events />}></Route>
   <Route path="/events/:id" element={<EventBlogById />}></Route>
   <Route path="/login" element={<Login />}></Route>
   <Route path="/register" element={<Signup />}></Route>
   <Route path="/apply" element={<Apply />}></Route>
   <Route path="/team/:id" element={<TeamPage />}></Route>
  </Routes>
 );
};

export default AppRoutes;
